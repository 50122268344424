.music-track-settings {
    .music-track-search-item {
        cursor: pointer;
        display: flex;

        .album-art > img {
            width: 64px;
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            margin-left: 16px;

            .artists {
                font-size: 14px;
            }
        }

        .selected-loading {
            align-items: center;
            display: flex;
            margin-left: 16px;
        }
    }
}
