.bible-element {
    .bible-element-header {
        display: flex;
        font-size: 18px;
        font-weight: 900;

        .passage {
            flex-grow: 1;
        }

        .translation-selector {
            margin-top: -10px;
        }
    }

    .verses {
        border-left: 1px solid #e6e6e6;

        p {
            color: var(--ion-color-dark, #222428);
            font-size: 17px;
            line-height: 24px;
            margin: 8px 16px;
            white-space: pre-line;

            .verse-number {
                font-size: 12px;
                font-weight: 600;
                margin: 0 3px;
                vertical-align: text-bottom;
            }
        }
    }

    h4 {
        margin: 0;
    }
}
