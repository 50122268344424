@import "../../theme/theme";

.user-profile-card {
    cursor: pointer;
    height: 100%;
    padding: 8px 8px 0;

    .MuiCardContent-root:last-child {
        padding-bottom: 8px;
    }

    .profile-card {
        display: flex;

        .avatar {
            height: 56px;
            margin-right: 8px;
            width: 56px;
        }
    }

    .bio {
        margin: 8px 0 16px;
    }
}

@media (min-width: $breakpoint-xs) {
    .user-profile-card {
        margin: 0;
    }
}

@media (min-width: $breakpoint-sm) {
    .user-profile-card {
        margin: 8px;
    }
}
