#devotional-builder-page {
    #image-search-grid-scrollable-target {
        padding-top: 1rem;

        .MuiTabs-root {
            padding-bottom: 1rem;
        }

        .upload-cover-image-background-panel {
            display: flex;
            flex-direction: column;

            .button-container {
                align-self: center;
            }
        }
    }
}
