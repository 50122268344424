.image-search-item {
    cursor: pointer;
    padding: 4px 8px;
    position: relative;

    &:hover {
        img {
            filter: brightness(75%);
        }

        a.creator-attribution {
            display: block;
        }
    }

    & > img {
        border-radius: 8px;
        height: auto;
        width: 100%;
    }

    a.creator-attribution {
        bottom: 12px;
        color: #fff;
        display: none;
        left: 16px;
        position: absolute;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
