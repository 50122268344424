.youtube-settings {
    .error-message {
        margin-top: 16px;
        text-align: center;

        svg {
            font-size: 48px;
        }
    }

    .youtube-preview {
        margin: 16px 16px 0;

        img {
            width: 100%;
        }
    }
}
