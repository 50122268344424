.music-track-panel {
    .ReactCollapse--collapse {
        transition: height 250ms;
    }

    .track-contents {
        display: flex;
        transition: 250ms;

        &.sm {
            margin-left: 4px;
            margin-top: 4px;

            .album-art {
                img,
                .react-loading-skeleton {
                    height: 44px;
                    width: 44px;
                }
            }
        }

        .album-art {
            img,
            .react-loading-skeleton {
                display: block;
                height: 80px;
                transition: 250ms;
                width: 80px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            font-size: 15px;
            justify-content: center;
            margin-left: 10px;

            .track-name {
                font-weight: 900;
            }

            .artist-names {
                color: var(--ion-color-medium, #92949c);
                font-weight: 700;
            }
        }

        .open-links {
            align-items: center;
            display: flex;
            flex-shrink: 0;

            ion-button {
                font-size: 12px;
            }
        }
    }

    .track-links {
        .btn-music-service-link {
            --border-radius: 100px;

            ion-icon {
                margin-right: 4px;
            }
        }
    }
}
