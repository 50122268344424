.bible-settings {
    display: flex;
    flex-direction: row;

    .scrollable-column {
        max-height: calc(100vh - 232px);
        overflow: auto;
    }

    .circular-buttons {
        display: flex;
        flex-flow: wrap;
    }

    .circular-button {
        border: 1px solid #d3d3d3;
        border-radius: 100px;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        margin: 5px;
        padding: 0;
        text-align: center;
        width: 40px;

        &.selected,
        &.first-verse,
        &.last-verse,
        &.inbetween-verse {
            background: #3cb371;
            border: 1px solid #3cb371;
            color: #fff;
        }

        &.first-verse {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            margin-right: 0;
            text-indent: -5px;
            width: 45px;
        }

        &.last-verse {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            margin-left: 0;
            text-indent: 5px;
            width: 45px;
        }

        &.inbetween-verse {
            border-radius: 0;
            margin: 5px 0;
            width: 50px;
        }
    }
}
