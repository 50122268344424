.daily-content-canvas-element-placeholder {
    .MuiPaper-root {
        padding: 12px 8px;

        .header {
            display: flex;
            justify-content: center;

            h3 {
                margin: 0 4px;
            }
        }

        .button-wrapper {
            display: flex;
            justify-content: center;

            button {
                margin: 8px 16px 0;
                width: calc(100% - 32px);
            }
        }
    }
}
