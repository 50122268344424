.cover-image-designer-container {
    .MuiPaper-root.MuiPaper-rounded {
        border-radius: 0;
        position: relative;
    }

    .canvas-loading {
        align-items: center;
        background: #fff;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 100;
    }
}