.button-options {
    display: flex;
    flex-direction: row;

    .align-option,
    .color-option {
        cursor: pointer;
        height: 32px;
        margin-right: 10px;
        width: 32px;
    }

    .color-option {
        border: 2px solid grey;

        &.black {
            background: #000;
        }

        &.white {
            background: #fff;
        }
    }
}
