$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;
$primary-color: #3cb371;

html {
    --ion-color-base: #3cb371;
    --ion-color-contrast: #fff;
    --ion-color-medium: #92949c;
}

a {
    color: #3cb371;
    text-decoration: none;
}

.primary-color {
    color: #3cb371;
}

.MuiButton-root.sm-button {
    border-radius: 100px;
    box-shadow: none;
    font-size: 14px;
    height: 28px;
}

.MuiButton-root.lg-button {
    border-radius: 100px;
    box-shadow: none;
    font-size: 16px;
    height: 40px;
}

.modal {
    background: #fff;
    border-radius: 16px;
    left: 50%;
    padding: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}