.edit-profile-modal {
    .profile-avatar {
        align-items: center;
        display: flex;
        flex-direction: column;

        .MuiAvatar-root {
            cursor: pointer;
            height: 100px;
            width: 100px;
        }
    }
}
