.daily-content-canvas {
    div[data-rbd-droppable-id*="daily-content-canvas"] {
        height: 100%;
    }

    .empty-canvas-message {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 2rem;
        text-align: center;
    }
}