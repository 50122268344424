#start-up-page {
    font-family: "Roboto";
    width: 100%;

    .lg-button {
        font-size: 18px;
        width: 50%;
    }

    .partners {
        .MuiGrid-item {
            display: flex;
            justify-content: center;

            img {
                object-fit: contain;
                padding: 8px;
                width: 80%;
            }
        }
    }

    .features {
        img {
            width: 100%;
        }
    }
}