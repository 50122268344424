.menu {
    $drawer-width: 240;

    flex-shrink: 0;    
    width: $drawer-width;

    .drawer-container {
        margin-top: 8px;
        overflow: auto;
    }
}