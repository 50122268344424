@import "../../theme/theme";

#login-page {
    .paper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
    }

    .avatar {
        background-color: $primary-color;
        margin: 8px;
    }

    .form {
        margin-top: 8px;
        width: 100%;
    }

    .submit {
        margin: 24px 0 8px;
    }

    .forgotten-password {
        margin-bottom: 8px;
        text-align: center;
    }
}