.header.MuiAppBar-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 1000;

    .avatar {
        cursor: pointer;
        position: absolute;
        right: 16px;
    }

    .logo-image {
        height: 58px;
        padding: 4px;
    }

    .toolbar-contents {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

#header-popper {
    z-index: 1000;

    .header-popper-box {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        margin-top: 4px;
    }

    .header-popper-list {
        align-items: start;
        flex-direction: column;

        .MuiListItemButton-root {
            width: 100%;
        }
    }
}
