#members-page {
    .box {
        display: flex;
        margin-bottom: 24px;
    }

    .add-member-button {
        margin-left: auto;
    }

    .MuiTableCell-head {
        .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.6);
            font-size: 20px;
            margin-left: 4px;
            vertical-align: top;
        }
    }
}

.add-member-modal {
    .MuiSvgIcon-root[data-testid="InfoIcon"] {
        color: rgba(0, 0, 0, 0.6);
        font-size: 18px;
        margin-left: -8px;
        vertical-align: middle;
    }
}
