.daily-content-toolbox.MuiContainer-root {
    max-width: fit-content;

    #daily-content-toolbox-modal {
        padding: 3rem;

        .daily-content-toolbox-group {
            margin-top: 16px;

            .daily-content-toolbox-group-buttons {
                display: flex;

                .daily-content-toolbox-item {
                    align-items: center;
                    cursor: grab;
                    display: flex;
                    flex-direction: column;
                    margin: 8px 0;
                    width: 88px;

                    svg {
                        font-size: 32px;
                    }

                    .MuiTypography-root {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
