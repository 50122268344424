.youtube-frame {
    .youtube-container {
        clear: both;
        float: none;
        height: 0;
        padding-bottom: 56.25%;
        padding-top: 25px;
        position: relative;
        width: 100%;

        iframe {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .cover {
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1000;
        }
    }
}
