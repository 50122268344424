#home-page {
    display: flex;
    flex-flow: row wrap;
    padding: 24px 0;
    width: 100%;

    .box {
        display: flex;
    }

    .create-business-account-button,
    .create-devotional-button {
        margin-left: auto;
    }
}