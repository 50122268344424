.devotional-element {
    padding-bottom: 6px;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;

    &:nth-of-type(1) {
        padding-top: 2rem;
    }

    &:last-of-type {
        padding-bottom: 2rem;
    }

    .element {
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 3px;
        position: relative;

        &.selected {
            border: 2px #ababab dashed;

            .devotional-element-buttons {
                display: flex;
            }
        }

        .devotional-element-buttons {
            background: #fff;
            display: none;
            margin: 2px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;

            button {
                margin: 0 3px;
                padding: 0;

                svg {
                    font-size: 22px;
                }
            }
        }
    }
}
