#devotional-builder-page .daily-content-tab {
    display: flex;

    .main-panel {
        width: 480px;

        .daily-content-canvas.MuiContainer-root {
            margin-top: 0;

            .daily-content-canvas-modal {
                height: 100%;
                padding: 0;
            }
        }
    }

    .side-panel {
        width: calc(50% - 255px);
    }
}
