#devotional-builder-page {
    flex-grow: 1;
    width: 100%;

    .header-buttons {
        left: 8px;
        position: absolute;
        top: 20px;
        z-index: 1000;
    }

    &.plan-information,
    &.cover-image-designer,
    &.daily-content,
    &.publish {
        background-image: linear-gradient(to left top, #00898c, #00968c, #00a385, #00ae77, #00b962);

        .next-step-button {
            border-color: #fff;
            color: #fff;
        }

        .builder-flow .MuiStepLabel-label {
            color: #fff;
        }
    }

    main {
        flex-grow: 1;
    }

    .builder-flow .MuiStepButton-root {
        padding: 4px 16px;
    }

    .next-step-button {
        min-width: 122px;
    }

    .tab-padding-top {
        padding-top: 50px;
    }

    .body-container {
        margin-top: 8px;

        .MuiContainer-root.paper-panel {
            height: calc(100vh - 200px);
            margin-top: 30px;
            position: relative;

            &.cover-image-photo-search {
                height: calc(100vh - 159px);
                margin-top: 0;
            }

            &.main-panel {
                margin-top: 0;
            }

            .MuiPaper-root {
                max-height: 100%;
                overflow-y: auto;
            }
        }
    }

    .paper-modal {
        padding: 3rem 2rem 2rem;
    }

    .MuiPaper-root.MuiPaper-rounded {
        border-radius: 10px;
    }

    .circular-progress-wrapper {
        margin: 16px 0;
        text-align: center;
    }

    .hidden-cover-image {
        height: 1080px;
        left: -10000px;
        position: absolute;
        top: -10000px;
        width: 1920px;
    }

    ::-webkit-scrollbar {
        background-color: #fff;
        border-radius: 4px;
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }
}

.MuiModal-root {
    ::-webkit-scrollbar {
        background-color: #fff;
        border-radius: 4px;
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }

    .circular-progress-wrapper {
        margin: 16px 0;
        text-align: center;
    }
}
