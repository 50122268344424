.list-element {
    font-size: 17px;
    line-height: 24px;
    margin: 0;
    padding-inline-start: 30px;
    white-space: pre-line;

    li {
        font-weight: 700;

        span {
            font-weight: 400;
        }
    }
}
