.daily-content-day-selector.MuiContainer-root {
    width: fit-content;

    #daily-content-day-selector-modal {
        min-width: 320px;
        overflow-x: hidden;
    }

    .day-selector-item {
        align-items: center;
        border-bottom: 1px solid lightgrey;
        cursor: pointer;
        display: flex;
        justify-content: left;
        list-style: none;
        padding: 8px;

        svg[data-testid="DragHandleIcon"] {
            margin-right: 8px;
        }

        .day-label {
            color: rgba(0, 0, 0, 0.54);
            font-size: 14px;
            font-weight: 900;
            margin-right: 8px;
            text-transform: uppercase;
            white-space: nowrap;
        }

        .text-label {
            flex-grow: 1;
            font-size: 16px;
            font-weight: 700;
        }

        .copy-day-button {
            margin-left: 8px;
        }

        .delete-day-button {
            padding: 5px;
        }
    }
}
