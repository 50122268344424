.MuiAvatar-root.paper-avatar {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
    color: #fff;
    height: 80px;
    left: 50%;
    position: absolute;
    top: -50px;
    transform: translate(-50%, 0);
    width: 80px;
    z-index: 1000;

    .MuiSvgIcon-root {
        font-size: 2rem;
    } 
}
