.app {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .body {
        display: flex;
        flex-grow: 1;

        main {
            flex-grow: 1;
            padding: 24px 5%;
        }
    }

    .container {
        margin-top: 24px;
    }
}