.cover-image-tool-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .cover-image-tool-box-item.MuiPaper-root {
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 6px;
        text-align: center;
        width: 48px;
    }
}
