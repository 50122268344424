.app .body {
    .two-factor-authentication-modal {
        background: #fff;
        height: calc(100% - 66px);
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 100000;

        main {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding-top: 72px;
        }

        h3 {
            margin-bottom: 8px;
        }

        .submit {
            margin-top: 16px;
        }

        .cancel {
            margin-top: 8px;
        }
    }
}
